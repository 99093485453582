import { redirect } from "react-router-dom";
import { ILanguages } from "../models/languages";
import {
  ILoginUser,
  IEmailUser,
  IRegisteringUser,
} from "../services/User.service";

export async function login(params: ILoginUser) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }
  );

  return response.json();
}

export async function confirmEmail(params: { mailConfirmationId: string }) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/confirm-email`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }
  );

  return response.json();
}

export async function resetPassword(params: IEmailUser) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/reset-password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }
  );

  return response.json();
}

export async function newPassword(params: any) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/new-password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }
  );

  return response.json();
}

export async function register(params: IRegisteringUser) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/register`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }
  );

  return response.json();
}

export async function getCountries() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/countries`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
}

export async function getMe(token: string) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/me`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status === 401) {
    localStorage.removeItem(token);
    redirect("/login");
    return;
  }

  return response.json();
}

export async function messages(token: string) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/phone/messages`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.json();
}
export async function getSessionUrl(
  token: string,
  phoneNumber: string,
  testPriceKey: string
) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/phone/buy`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ phoneNumber, testPriceKey }),
    }
  );

  return response.json();
}

export async function getPhoneNumberCountriesList() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/phone/countries`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
}

export async function getNumbersByCountry() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/phone/get-number`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
}

export async function cancelSubscription(token: string) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/phone/abandon`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.json();
}

export async function deleteAccount(token: string) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/delete`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.json();
}

export async function changeExpectation(
  token: string,
  params: { isExpectingEmail: boolean }
) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/phone/change-expectancy`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
  );

  return response.json();
}

export async function googleLogin(token: string) {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/users/google-login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.json();
}

export async function getUserLang() {
  const countries = {
    AM: "hy",
    RU: "ru",
    US: "en",
    UA: "ua",
  };

  const res = await fetch(
    `${process.env.REACT_APP_SERVER_PATH}/countries/user-country`
  );

  const { country }: { country: ILanguages } = (await res.json()) || "US";

  const lang = countries[country] || "en";

  return lang;
}
