import { useState } from "react";
import { AuthContext } from "../services/AuthContext";
import { IUser } from "../services/User.service";


const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState(null as IUser | null);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export default UserProvider;