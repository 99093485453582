import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useUser } from "../../services/User.service";
import { usePrice } from "../../services/usePrice";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);

const Payment = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const phoneNumber = queryParams.get("phoneNumber") as string;

  const useUserService = useUser();
  const [, testPriceKey] = usePrice();

  const fetchClientSecret = async () => {
    const { clientSecret } = await useUserService.getSessionUrl(
      phoneNumber,
      testPriceKey
    );

    return clientSecret;
  };

  const options = { fetchClientSecret };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};

export default Payment;
