import { MouseEvent, useState } from "react";
import { Button, Link } from "@nextui-org/react";
import posthog from "posthog-js";
import { useLocalStorage } from "../../services/useLocalStorage";
import {
  ITestButtonsDataKeys,
  testButtonsData,
} from "../../consts/testButtonsData";
import { useTranslation } from "react-i18next";

const delay = 1000;

export const TestButton = ({ type }: { type: ITestButtonsDataKeys }) => {
  const { text, event, localStorageKey, icon } = testButtonsData[type];

  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const { getItem, setItem } = useLocalStorage();
  const { t } = useTranslation();

  const size = type === "notification" ? "sm" : "lg";
  const variant = type === "notification" ? "light" : "solid";

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setTooltipVisible(true);

    const hasClicked = getItem(localStorageKey);

    if (!hasClicked) {
      posthog.capture(event);

      setItem(localStorageKey, "true");
    }

    setTimeout(() => {
      setTooltipVisible(false);
    }, delay);
  };

  return (
    <div className="flex justify-center">
      {isTooltipVisible ? (
        <div
          className="text-center text-lg bg-red"
          style={{ minHeight: "44px", minWidth: "64px" }}
        >
          {t("comingSoon")}🔥
        </div>
      ) : (
        <Link
          href="#"
          className="w-full"
          onClick={handleClick}
          style={{ maxWidth: "768px" }}
        >
          <Button
            size={size}
            color="primary"
            variant={variant}
            className={`w-full h-full text-lg flex justify-center items-center p-2 mx-2 opacity-1`}
            isDisabled
          >
            {icon} {t(text)}
          </Button>
        </Link>
      )}
    </div>
  );
};
