import posthog from "posthog-js";

const posthogApiKey = process.env.REACT_APP_PH_API_KEY as string;
const posthogOptions = {
  api_host: process.env.REACT_APP_PH_HOST,
  "opt_in_site_apps": true,
};

if (posthogApiKey) {
  posthog.init(posthogApiKey, posthogOptions);
}

export default posthog;
