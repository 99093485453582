import React from "react";
import { Helmet } from "react-helmet";
import { Button, Spacer, Link } from "@nextui-org/react";
import {
  INotificationsDataKeys,
  notificationsData,
} from "../../consts/notificationsData";
import { useTranslation } from "react-i18next";

export const NotificationData = ({
  type,
}: {
  type: INotificationsDataKeys;
}) => {
  const { t } = useTranslation();

  const { buttonColor, buttonText, icon, text, title, href, hasButton } =
    notificationsData[type];

  return (
    <>
      <Helmet>
        <title>{t(title)}</title>
        <meta name="description" content="Success!" />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        {icon && (
          <img
            src={icon}
            alt="SVG icon"
            style={{ width: "150px", height: "150px" }}
          />
        )}
        <p style={{ fontSize: "32px", marginBottom: "16px" }}>{t(text)}</p>
        <Spacer y={2} />
        {hasButton && (
          <Button
            href={href}
            as={Link}
            color={buttonColor}
            variant="solid"
            size="lg"
          >
            {t(buttonText)}
          </Button>
        )}
      </div>
    </>
  );
};

export default NotificationData;
