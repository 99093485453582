import { useTranslation } from "react-i18next";
// import { TestButton } from "../common/TestButton";

export const Footer = () => {
  const { t } = useTranslation();
  const email = process.env.REACT_APP_CONTACT_EMAIL;

  return (
    <footer className="w-full mt-40 text-xs text-gray-500 text-center">
      {/* commented this because everyone was clicking on this button and it did't work as expected. */}
      {/* <TestButton type="mobileApp" /> */}

      <p className="flex justify-center mt-4">
        <a className="underline " href={`mailto:${email}`}>
          {email}
        </a>
      </p>
      <br />
      <p className="mt-2">
        lovepowered llc{" "}
        <a href="/privacy-policy" className="underline">
          {t("privacyPolicy")}
        </a>
      </p>
    </footer>
  );
};
