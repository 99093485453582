import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const NoPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("pageNotFoundTitle")}</title>
      </Helmet>
      <h1 className="text-7xl text-center">{t("pageNotFoundText")} 🐩</h1>
    </>
  );
};
