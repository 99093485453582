import { useTranslation } from "react-i18next";

export enum ELoginQueries {
  "resetPassword" = "resetPassword",
  "mailConfirmation" = "mailConfirmation",
}

export type ILoginNotices = "mailConfirmation" | "resetPassword";
interface INotificationProps {
  type: "failedPayment" | ILoginNotices;
}

enum ENotificationTranslationsKeys {
  failedPayment = "paymentFailedNotification",
  mailConfirmation = "mailConfirmationNotification",
  resetPassword = "resetPasswordNotification",
}

const notifications = {
  failedPayment: {
    className: "text-danger-500",
    text: ENotificationTranslationsKeys.failedPayment,
  },
  mailConfirmation: {
    className: "",
    text: ENotificationTranslationsKeys.mailConfirmation,
  },
  resetPassword: {
    className: "",
    text: ENotificationTranslationsKeys.resetPassword,
  },
};

export default function Notification({ type }: INotificationProps) {
  const { t } = useTranslation();
  
  const notification = notifications[type] || { className: "", text: "" };
  const { className, text } = notification;

  return (
    <div className={`flex flex-row justify-center ${className}`}>{t(text)}</div>
  );
}
