import {
  Card,
  Link,
  CardBody,
  Divider,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Checkbox,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../services/User.service";

export const Settings = () => {
  const [isSelected, setIsSelected] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteOrCancel, setDeleteOrCancel] = useState("delete");

  const navigate = useNavigate();
  const useUserService = useUser();

  const { t } = useTranslation();

  useEffect(() => {
    const getMe = async () => {
      const { isExpectingEmail } = await useUserService.getMe();

      setIsSelected(isExpectingEmail);
    };

    getMe();
  });

  const cancelSubscription = async () => {
    try {
      await useUserService.cancelSubscription();
      navigate("/buy-number");
    } catch (err) {
      alert(err);
    }
  };

  const deleteAccount = async () => {
    try {
      await useUserService.deleteAccount();
      useUserService.removeUser();
      navigate("/deleted-account");
    } catch (err) {
      alert(err);
    }
  };

  const changeMessageExpectant = async () => {
    setIsSelected(!isSelected);

    await useUserService.changeExpectation(!isSelected);
  };

  return (
    <div>
      <h1 className="text-3xl mt-4 mb-2">{t("settings")}</h1>
      <Card>
        <div className="p-4">
          <Checkbox
            defaultSelected
            color="primary"
            isSelected={isSelected}
            onValueChange={changeMessageExpectant}
          >
            {t("receiveEmail")}
          </Checkbox>
        </div>
      </Card>
      <Card className="w-full mb-4 mt-4">
        <CardHeader>
          <h2 className="text-2xl mt-4">{t("recoverPasswordTitle")}</h2>
        </CardHeader>
        <Divider />
        <CardBody>
          <Link href="/forgot-password" color="primary">
            <p>{t("recoverPasswordText")}</p>
          </Link>
        </CardBody>
      </Card>

      <Card className="w-full mb-4">
        <CardHeader>
          <h2 className="text-2xl mt-4">{t("cancelSubscription")}</h2>
        </CardHeader>
        <Divider />
        <CardBody>
          <Link
            href=""
            onPress={() => {
              setDeleteOrCancel("cancel");
              onOpen();
            }}
            color="danger"
          >
            <p>{t("cancelUpcomingPayments")}</p>
          </Link>
        </CardBody>
      </Card>

      <Card className="w-full mb-4">
        <CardHeader>
          <h2 className="text-2xl mt-4">{t("deleteAccount")}</h2>
        </CardHeader>
        <Divider />
        <CardBody>
          <Link
            href=""
            onPress={() => {
              setDeleteOrCancel("delete");
              onOpen();
            }}
            color="danger"
          >
            <p>{t("getRidCompletely")}</p>
          </Link>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false}>
        <ModalContent>
          {deleteOrCancel === "delete"
            ? (onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {t("caution")}
                  </ModalHeader>
                  <ModalBody>
                    <p>{t("ifYouDeleteAccount")}</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                      {t("close")}
                    </Button>
                    <Button
                      color="danger"
                      onPress={() => {
                        setDeleteOrCancel("confirmDelete");
                        onOpen();
                      }}
                    >
                      {t("deleteAccount")}
                    </Button>
                  </ModalFooter>
                </>
              )
            : deleteOrCancel === "confirmDelete"
            ? (onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {t("caution")}
                  </ModalHeader>
                  <ModalBody>
                    <p>{t("areYouAbsolutelySure")}</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                      {t("close")}
                    </Button>
                    <Button color="danger" onPress={deleteAccount}>
                      {t("confirmDelete")}
                    </Button>
                  </ModalFooter>
                </>
              )
            : (onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {t("caution")}
                  </ModalHeader>
                  <ModalBody>
                    <p>{t("sureCancelSubscription")}</p>
                    <p>{t("youMayLoseNumber")}</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                      {t("close")}
                    </Button>
                    <Button color="danger" onPress={cancelSubscription}>
                      {t("confirmCancelling")}
                    </Button>
                  </ModalFooter>
                </>
              )}
        </ModalContent>
      </Modal>
    </div>
  );
};
