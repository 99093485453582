import { countries, Country } from "countries-list";

export interface ICountry {
  name: string;
  isoCountry: string;
  emoji: string;
  local?: boolean;
}

export const countryNameArray = Object.values(countries)
  .map((country: Country) => country.name)
  .sort();
