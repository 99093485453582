import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  Button,
  Link,
} from "@nextui-org/react";

import { useUser } from "../services/User.service";

import logout from "../svgs/logout.svg";
import config from "../svgs/config.svg";
import { Logo } from "../svgs/logo";
import { TestButton } from "./common/TestButton";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

export const AppNavbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, removeUser } = useUser();

  const logoutUser = () => {
    removeUser();
    navigate("/login");
  };

  return (
    <>
      <Navbar isBordered className="flex-wrap">
        <NavbarBrand>
          <Link href="/">
            <Logo />
          </Link>
        </NavbarBrand>

        <NavbarContent justify="end">
          {/* <LanguageSwitcher /> */}
          {/* TODO: move to env */}
          <Link href="https://blog.get-sms.app" target="_blank" color="primary">
            {t("blog")}
          </Link>
          {user ? (
            <div className="flex items-center">
              <div style={{ maxWidth: "48px" }}>
                <TestButton type="notification" />
              </div>
              <Link className="m-3 w-7 h-7" href="/settings" color="primary">
                <img src={config} alt="config icon" />
              </Link>
              <Button
                onClick={logoutUser}
                color="warning"
                variant="bordered"
                startContent={<img src={logout} alt="logout icon" />}
              ></Button>
            </div>
          ) : (
            <>
              <Link href="/login" color="primary">
                {t("login")}
              </Link>
            </>
          )}
        </NavbarContent>
      </Navbar>
    </>
  );
};
