import { createContext } from "react";
import { IUser } from "./User.service";

interface IAuthContext {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => { },
});
