import { Helmet } from "react-helmet";
import { Button, Card, Link } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../services/User.service";
import { usePrice } from "../../services/usePrice";
import { getPrice } from "../../helpers/getPrice";
import { FAQ } from "../common/FAQ";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "../GoogleLogin";

export default function Landing() {
  const navigate = useNavigate();
  const { user, getToken, initUser, googleLogin } = useUser();
  const [secondLoad, setSecondLoad] = useState(false);
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);

  const accessToken = queryParams.get("accessToken") || "";
  const promo = queryParams.get("promo") || "";

  const [price] = usePrice(promo);

  useEffect(() => {
    const login = async () => {
      if (!accessToken) {
        return;
      }

      await googleLogin(accessToken);

      navigate("/sms");
    };

    login();
  }, []);

  useEffect(() => {
    const call = async () => {
      if (getToken()) {
        await initUser();
      }

      return setSecondLoad(true);
    };

    call();
  }, []);

  useEffect(() => {
    if (!secondLoad || !user) {
      // reverse logic of authroute
      return;
    }

    navigate("/sms");
  }, [user, secondLoad]);

  const specialOffer = getPrice(price);
  const prevSpecialOffer = getPrice(Math.ceil(price * 2));

  // const pricePerMonth = getPrice(price);

  if (!secondLoad || user) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{t("landingTitle")}</title>
      </Helmet>
      {/* header section */}
      <header className="flex flex-col md:flex-row min-h-screen">
        <div className="col-md md:justify-center flex flex-col md:w-1/2">
          <div className="col-span-2 mt-5">
            <h1 className="text-4xl">{t("heroTitle")}</h1>
          </div>
          <Card className="bg-gray-200 p-5 mt-[80px]">
            <h1 className="text-3xl">
              <p>{t("heroText")}</p>
            </h1>
            <h3 className="mt-16 text-3xl">
              <s>{prevSpecialOffer}</s>{" "}
              <b className="text-5xl">{specialOffer}</b>{" "}
              <span>{t("specialOffer")}</span>
            </h3>
            <div className="flex flex-col lg:flex-row md:justify-between ">
              <div className="mt-4">
                <GoogleLogin />
              </div>

              <Button
                as={Link}
                size="lg"
                href="/register"
                color="primary"
                className="mt-4 font-bold"
              >
                {t("registerNow")}
              </Button>
            </div>
          </Card>
        </div>
        <div className="col-md md:flex md:w-1/2">
          <div className=" w-full mt-10 p-1.5 flex justify-center items-center">
            <img
              className=""
              style={{ maxHeight: "80vh" }}
              src="/images/screenshot.png"
              alt="telegram logo"
            />
          </div>
        </div>
      </header>
      {/* Second section 2 */}
      <div className="gap-5  mt-24">
        <Card className="col-span-2 sm:col-span-1 p-10 flex justify-center items-center gap-5">
          <h1 className="text-5xl">{t("ourTopPriority")}</h1>
          <p className="text-xl mt-10">{t("ourServicePrioritizes")}</p>
          <Button
            as={Link}
            size="lg"
            href="/register"
            color="primary"
            className="mt-4 text-3xl p-5 font-bold w-80"
          >
            {t("join")}
          </Button>
        </Card>
      </div>

      {/*FAQ */}
      <div className="mt-24">
        <FAQ />
      </div>
    </>
  );
}
