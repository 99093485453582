import checkmark from "../svgs/checkmark.svg";
import envelope from "../svgs/envelope.svg";
import password from "../svgs/password.svg";
export interface INotificationsDataType {
  text: string;
  icon: any;
  hasButton: boolean;
  buttonColor: "primary" | "success";
  buttonText: string;
  title: string;
  href: string;
}
export type INotificationsDataKeys =
  | "mailConfirmation"
  | "recoverPassword"
  | "successPayment"
  | "accountDeleted";

enum ETextValues {
  mailConfirmation = "mailConfirmationNotificationText",
  recoverPassword = "recoverPasswordNotificationText",
  successPayment = "successPaymentNotificationText",
  accountDeleted = "accountDeletedNotificationText",
}

enum ETitleValues {
  mailConfirmation = "mailConfirmationTitle",
  recoverPassword = "recoverPasswordTitle",
  successPayment = "successPaymentTitle",
  accountDeleted = "accountDeletedTitle",
}

enum EButtonTextValues {
  mailConfirmation = "",
  recoverPassword = "loginLowercase",
  successPayment = "successPaymentNotificationDataButtonText",
  accountDeleted = "",
}

export const notificationsData: Record<
  INotificationsDataKeys,
  INotificationsDataType
> = {
  mailConfirmation: {
    text: ETextValues.mailConfirmation,
    icon: envelope,
    hasButton: false,
    buttonColor: "primary",
    buttonText: EButtonTextValues.mailConfirmation,
    title: ETitleValues.mailConfirmation,
    href: "",
  },
  recoverPassword: {
    text: ETextValues.recoverPassword,
    icon: password,
    hasButton: true,
    buttonColor: "primary",
    buttonText: EButtonTextValues.recoverPassword,
    title: ETitleValues.recoverPassword,
    href: "/login",
  },
  successPayment: {
    text: ETextValues.successPayment,
    icon: checkmark,
    hasButton: true,
    buttonColor: "primary",
    buttonText: EButtonTextValues.successPayment,
    title: ETitleValues.successPayment,
    href: "/sms",
  },
  accountDeleted: {
    text: ETextValues.accountDeleted,
    icon: "",
    buttonColor: "primary",
    buttonText: EButtonTextValues.accountDeleted,
    hasButton: false,
    href: "",
    title: ETitleValues.accountDeleted,
  },
};
