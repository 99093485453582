import { Card, Accordion, AccordionItem } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export const FAQ = () => {
  const { t } = useTranslation();
  const faqArray = [
    {
      titleKey: "isOneTimeQuestion",
      desciptionKey: "isOneTimeAnswer",
    },
    {
      titleKey: "passportNeedsQuestion",
      desciptionKey: "passportNeedsAnswer",
    },
    {
      titleKey: "virtualNumberQuestion",
      desciptionKey: "virtualNumberAnswer",
    },
    {
      titleKey: "virtualNumberUsingQuestion",
      desciptionKey: "virtualNumberUsingAnswer",
    },
    {
      titleKey: "accessNumberQuestion",
      desciptionKey: "accessNumberAnswer",
    },
    {
      titleKey: "cantReceiveSmsQuestion",
      desciptionKey: "cantReceiveSmsAnswer",
    },
    {
      titleKey: "whatIsGetSmsQuestion",
      desciptionKey: "whatIsGetSmsAnswer",
    },
  ];

  return (
    <>
      <h1 className="text-7xl">FAQ</h1>
      <Card className="p-5 mt-10">
        <div>
          {faqArray.map(({ titleKey, desciptionKey }) => (
            <div className="p-5 border-b border-blacku-500">
              <h3 className="text-2xl pt-5 pb-5">{t(titleKey)}</h3>
              {t(desciptionKey)}
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};
