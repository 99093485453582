export type ITestButtonsDataKeys = "affiliate" | "mobileApp" | "notification";

interface ITestButtonsData {
  text: string;
  event: string;
  localStorageKey: string;
  icon: string;
}

enum ETextValues {
  affiliate = "affiliateTestButtonText",
  mobileApp = "mobileAppTestButtonText",
  notification = "",
}

export const testButtonsData: Record<ITestButtonsDataKeys, ITestButtonsData> = {
  affiliate: {
    text: ETextValues.affiliate,
    event: ETextValues.affiliate,
    localStorageKey: "hasClickedAffiliateLink",
    icon: "💫",
  },
  mobileApp: {
    text: ETextValues.mobileApp,
    event: "mobile-app-link-clicked",
    localStorageKey: "hasClickedMobileAppLink",
    icon: "📱",
  },
  notification: {
    text: ETextValues.notification,
    event: "notification-link-clicked",
    localStorageKey: "hasClickedNotificationLink",
    icon: "🔔",
  },
};
