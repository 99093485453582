import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useUser } from "../../services/User.service";
import { ELoginQueries } from "../common/Notification";
import { useTranslation } from "react-i18next";

export const MailConfirmation = () => {
  const { mailConfirmationId } = useParams();
  const useUserService = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const confirmEmail = async () => {
      const res = await useUserService.confirmEmail(
        mailConfirmationId as string
      );

      if (!res.errors) {
        navigate(`/login?redirectFrom=${ELoginQueries.mailConfirmation}`);
      }
    };

    confirmEmail();
  }, []);

  return (
    <Helmet>
      <title>{t("mailConfirmationTitle")}</title>
    </Helmet>
  );
};
