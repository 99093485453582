import { Button, Link, Input } from "@nextui-org/react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "../../services/User.service";
import { Helmet } from "react-helmet";
import Notification, { ILoginNotices } from "../common/Notification";
import { GoogleLogin } from "../GoogleLogin";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const redirectFrom = queryParams.get("redirectFrom") as ILoginNotices;

  const navigate = useNavigate();
  const useUserService = useUser();
  const [errors, setErrors] = useState({ message: "" });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const res = useUserService.getToken();
    if (res) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await useUserService.loginUser({
        email,
        password,
      });

      if (response.errors) {
        return setErrors(response.errors);
      }

      setErrors({ message: "" });
      navigate("/sms");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <form
      className="flex w-full flex-wrap gap-4 flex-col"
      onSubmit={handleSubmit}
    >
      <Helmet>
        <title>{t("loginLowercase")}</title>
      </Helmet>
      <h1 className="text-4xl">{t("loginLowercase")}</h1>
      <Notification type={redirectFrom} />
      <Input
        type="email"
        label={t("email")}
        radius="sm"
        size="lg"
        placeholder="youremail@email.com"
        onChange={(e: any) => {
          setErrors({ ...errors, message: "" });
          setEmail(e.target.value);
        }}
        isInvalid={!!errors.message}
        errorMessage={errors.message}
      />
      <Input
        type="password"
        label={t("password")}
        radius="sm"
        size="lg"
        placeholder="********"
        onChange={(e: any) => {
          setErrors({ ...errors, message: "" });
          setPassword(e.target.value);
        }}
      />
      <Button color="primary" size="lg" fullWidth={true} type="submit">
        {t("loginLowercase")}
      </Button>

      <GoogleLogin />

      <Link href="/register">{t("registerNewAccount")}</Link>
      <Link href="/forgot-password">{t("forgotPasswordQuestion")}</Link>
    </form>
  );
};
