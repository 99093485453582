import { Button, Link, Input } from "@nextui-org/react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../services/User.service";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const useUserService = useUser();
  const [errors, setErrors] = useState({ email: "" });

  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await useUserService.resetPassword({
        email,
      });

      if (response.errors) {
        return setErrors(response.errors);
      }

      setErrors({ email: "" });
      navigate("/recover-password");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <form className="flex w-full flex-wrap gap-4" onSubmit={handleSubmit}>
      <Helmet>
        <title>{t("forgotPasswordTitle")}</title>
      </Helmet>
      <h1 className="text-4xl">{t("forgotPasswordQuestion")}</h1>
      <Input
        type="email"
        label={t("email")}
        radius="sm"
        size="lg"
        placeholder="youremail@email.com"
        onChange={(e: any) => {
          setErrors({ ...errors, email: "" });
          setEmail(e.target.value);
        }}
        isInvalid={!!errors.email}
        errorMessage={errors.email}
      />
      <Button color="primary" size="lg" fullWidth={true} type="submit">
        {t("requestPasswordReset")}
      </Button>
      <Link href="/register">{t("registerNewAccount")}</Link>
      <Link href="/login">{t("loginLowercase")}</Link>
    </form>
  );
};
