import { useNavigate } from "react-router-dom";

import { useUser } from "../services/User.service";
import { useEffect, useState } from "react";

const AuthRoute = ({ component }: { component: React.ReactNode }) => {
    const navigate = useNavigate();
    const { user, removeUser, getToken, initUser } = useUser();
    const [secondLoad, setSecondLoad] = useState(false);

    useEffect(() => {
        const call = async () => {
            if (getToken()) {
                await initUser();
            }

            return setSecondLoad(true);
        };

        call();
    }, []);

    useEffect(() => {
        if (!secondLoad || user) {
            return;
        }

        removeUser();
        navigate('/login');
    }, [user, secondLoad]);


    return (<>{component}</>);
};

export default AuthRoute;