import { FormEvent, useState, useEffect } from "react";
import { Button, Link, Input, Select, SelectItem } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../services/User.service";
import { Helmet } from "react-helmet";
import { countryNameArray } from "../../consts/countries";
import { GoogleLogin } from "../GoogleLogin";
import { usePrice } from "../../services/usePrice";
import { getPrice } from "../../helpers/getPrice";
import { Trans, useTranslation } from "react-i18next";

export const Register = () => {
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    country: "",
  });

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [price] = usePrice();
  const { t } = useTranslation();

  const useUserService = useUser();

  useEffect(() => {
    const res = useUserService.getToken();
    if (res) {
      navigate("/sms");
    }
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await useUserService.registerUser({
        email,
        password,
        country,
      });

      if (response.errors) {
        return setErrors(response.errors);
      }
      navigate("/mail-confirmation");

      setErrors({ country: "", email: "", password: "" });
      navigate("/mail-confirmation");
    } catch (err) {
      alert(err);
    }
  };

  const pricePerMonth = getPrice(price);

  return (
    <>
      <Helmet>
        <title>{t("registerLowerCase")}</title>
      </Helmet>
      <form
        className="flex w-full flex-wrap gap-4 flex-col"
        onSubmit={handleSubmit}
      >
        <h1 className="text-4xl">{t("registration")}</h1>
        <div className="w-full">
          <p className="text-2xl mt-5">{t("welcomeToGetSms")}</p>
          <p className="mb-5">
            <Trans
              i18nKey="buyNumberIntro"
              values={{ pricePerMonth: pricePerMonth }}
              components={{ bold: <b /> }}
            />
            <a
              target="blank"
              href={process.env.REACT_APP_LANDING_PAGE_URL}
              className="underline"
            >
              {t("goToLanding")}
            </a>
          </p>
        </div>
        <Input
          type="email"
          label={t("email")}
          radius="sm"
          size="lg"
          placeholder="your@email.com"
          onChange={(e: any) => {
            setErrors({ ...errors, email: "" });
            setEmail(e.target.value);
          }}
          isInvalid={!!errors.email}
          errorMessage={errors.email}
        />
        <Input
          type="password"
          label={t("password")}
          radius="sm"
          size="lg"
          placeholder="********"
          onChange={(e: any) => {
            setErrors({ ...errors, password: "" });
            setPassword(e.target.value);
          }}
          isInvalid={!!errors.password}
          errorMessage={errors.password}
        />
        <Select
          label={t("selectCountry")}
          onChange={(e: any) => {
            setErrors({ ...errors, country: "" });
            setCountry(e.target.value);
          }}
          defaultSelectedKeys={[country]}
          isInvalid={!!errors.country}
          errorMessage={errors.country}
        >
          {countryNameArray.map((country) => (
            <SelectItem key={country} value={country}>
              {country}
            </SelectItem>
          ))}
        </Select>
        <Button type="submit" color="primary" size="lg" fullWidth={true}>
          {t("registerLowerCase")}
        </Button>
        <GoogleLogin />
        <div className="flex gap-4 mt-10 flex-col">
          <Link href="/login">{t("alreadyHaveAccount")}</Link>
          <Link href="/forgot-password">{t("forgotPasswordQuestion")}</Link>
        </div>
      </form>
    </>
  );
};
