import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./consts/translations";

const language = "language";
const currentLanguage = localStorage.getItem(language) || "en";

i18n.use(initReactI18next).init({
  resources: resources,
  lng: currentLanguage,
});

export default i18n;
