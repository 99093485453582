import { Button, Card, Link, Spacer, Spinner } from "@nextui-org/react";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

import { useUser } from "../../services/User.service";
import { usePrice } from "../../services/usePrice";
import { getPrice } from "../../helpers/getPrice";
import { useNavigate } from "react-router-dom";
import { FAQ } from "../common/FAQ";
import { useTranslation, Trans } from "react-i18next";

export interface IPhoneNumber {
  phoneNumber: string;
  friendlyName: string;
}

export interface IPhoneNumberResponse {
  phoneNumbers: IPhoneNumber[];
  errorMessage?: string;
}

export const BuyNumber = () => {
  const [price] = usePrice();
  const [phoneNumbersResponse, setPhoneNumbersResponse] =
    useState<IPhoneNumberResponse>({
      phoneNumbers: [],
    });
  const [isLoading, setIsLoading] = useState(false);
  const useUserService = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const getPhoneNumber = async () => {
      setIsLoading(true);
      const phoneNumberList = await useUserService.getNumbersByCountry();

      setPhoneNumbersResponse(phoneNumberList);
      setIsLoading(false);
    };

    getPhoneNumber();
  }, []);

  const pricePerMonth = getPrice(price);

  const goToPayment = (phoneNumber: string) => {
    navigate(`/payment?phoneNumber=${phoneNumber}`);
  };

  return (
    <>
      <Helmet>
        <title>{t("buyNumberTitle")}</title>
      </Helmet>
      <h2 className="text-3xl pl-4 mt-4">
        {t("selectNumber")} <b>{pricePerMonth}</b>:
      </h2>
      <Spacer y={2} />
      <div className="w-full flex justify-evenly flex-wrap mt-2">
        <>
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <>
              {phoneNumbersResponse.errorMessage ||
              !phoneNumbersResponse?.phoneNumbers.length ? (
                <p>{phoneNumbersResponse.errorMessage}</p>
              ) : (
                <>
                  {phoneNumbersResponse?.phoneNumbers.map((phoneNumber) => {
                    return (
                      <div>
                        <Button
                          className="m-1 bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
                          onClick={() => goToPayment(phoneNumber.phoneNumber)}
                        >
                          {phoneNumber.phoneNumber}
                        </Button>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      </div>
{/*       
      <Card className="mt-5 mb-5 p-5">
        <h1 className="text-2xl p-5">
          <Trans
            i18nKey="betterDeal"
            components={{ bold: <strong /> }}
            values={{ price: getPrice(1.99) }}
          />
        </h1>
        <div className="w-full flex justify-evenly flex-wrap">
          <Button
            color="primary"
            as={Link}
            target="_blank"
            size="lg"
            href="https://docs.google.com/forms/d/e/1FAIpQLSclx5JmtP8H8mba2IrSDx4hG-gQwJYbRH2Hfi_ZsVcKls3gMQ/viewform?usp=sf_link"
          >
            {t("fillForm")}
          </Button>
        </div>
      </Card> */}
      <FAQ></FAQ>
    </>
  );
};
