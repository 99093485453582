import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import googleIcon from "../svgs/google.svg";

export function GoogleLogin() {
  const { t } = useTranslation();
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_PATH}/auth/google`;
  };

  return (
    <Button
      onClick={handleLogin}
      size="lg"
      fullWidth={true}
      variant="bordered"
      className="border-gray-900"
    >
      <img src={googleIcon} alt="Google icon" /> {t("continueWithGoogle")}
    </Button>
  );
}
