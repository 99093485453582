import * as enTranslations from './en.json';
import * as ruTranslations from './ru.json';
import * as hyTranslations from './hy.json';
import * as uaTranslations from './ua.json';
import * as hiTranslations from './hi.json';


export const resources = {
  en: enTranslations,
  ru: ruTranslations,
  hy: hyTranslations,
  ua: uaTranslations,
  in: hiTranslations,
};
